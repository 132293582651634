<template>
	<div class="dir-setting">
		<div class="header">
			<el-button size="small" type="primary" icon="el-icon-plus" round @click="handleCreate" v-permission="['/hrms/position/dict/add']">添加分类</el-button>
			<el-input size="small" suffix-icon="el-icon-search" placeholder="输入分类名称关键字" v-model="keyword"></el-input>
		</div>
		<div class="content">
			<el-table :data="filterDirList" style="width: 100%" :header-cell-style="{backgroundColor: '#EBF3FF'}"
				max-height="500">
				<el-table-column type="index" label="序号" width="100"> </el-table-column>
				<el-table-column prop="label" label="分类名称">
				</el-table-column>
				<el-table-column label="操作" width="200" align="center">
					<template slot-scope="scope" v-if="!disabledTypes.includes(scope.row.label)">
						<div class="operate">
							<el-button size="mini" type="text" @click="handleEdit(scope.row)" v-permission="['/hrms/position/type/modify']">编辑</el-button>
							<el-button size="mini" type="text" style="color:#FF2525;" @click="handleDelete(scope.row)" v-permission="['/hrms/position/type/delete']">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import {
  addJobType,
  moveJobType,
  editJobType,
  delJobType,
} from "@/api/hrAPI.js";
import {getLibName} from "@/instruct"

export default {
  props: {
	libName: {
		type:String,
		require: true
	},
	disabledTypes: {
		type: Array,
		default: () => []
	},
	addPath: {
		type:String,
		default: ''
	}
  },
  data() {
    return {
      openEdit: false,
      editText: "",
      keyword: "",
      // filterDirList: []
    //   disabledTypes: [],
	  dirList: [],
	//   libKey: 'position_type'
    };
  },
  computed: {
    // dirList() {
	//   const { addPath } = this.$route.meta
	//   let key = 'position_type'
	//   if(addPath) {
	// 	key = key.concat(addPath).replace('/','_')
	//   } 

    //   return this.$store.getters.dictMap[key];
    // },
    filterDirList() {
      return this.dirList.filter((item) => item?.label?.includes(this.keyword));
    },
	libKey() {
		let key = 'position_type'
		if(this.addPath) {
			key = key.concat(this.addPath).replace('/','_')
		}

		return key
	},
	userPermission() {
		return this.$store.getters?.permissions || [];
	}
  },
  watch: {
	'addPath': {
		handler() {
			this.dirList = this.$store.getters.dictMap[this.libKey];
		},
		immediate: true
	}
  },
  mounted() {
    this.$nextTick(() => {
      this.setDragDropEvent();
    });
  },
  methods: {
    getTableData() {
      this.filterDirList = this.dirList.filter((item) =>
        item.label.includes(this.keyword)
      );
      this.$store.dispatch("common/getDictMap");
    },
    handleCreate() {
      this.editText = "添加分类";
      this.handleSubmit();
    },
    handleEdit(data) {
      this.editText = "编辑分类";
      this.handleSubmit(data);
    },
    handleSubmit(data) {
      this.$prompt("", this.editText, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "分类名不能为空",
        inputValue: data && data.label,
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          const params = {
            id: (data && data.id) || "",
            name: value,
          };
          if (params.id) {
			const path = '/hrms/position/type/modify'
		  	const note = `${this.libName}编辑${value}分类`
		  	this.$store.commit('status/SET_LOG', {path, note})
            editJobType(params).then((res) => {
              this.$message.success("操作成功");
              this.resetDirList().then(() => {
				this.updateDir()
			  })
            });
          } else {
			const path = '/hrms/position/dict/add'
		  	const note = `${this.libName}添加${value}分类`
		  	this.$store.commit('status/SET_LOG', {path, note})
            addJobType(params).then((res) => {
              this.$message.success("操作成功");
              this.resetDirList().then(() => {
				this.updateDir()
                this.$nextTick(() => {
                  this.setDragDropEvent();
                });
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDelete(data) {
      this.$confirm("确认要删除分类吗?删除后，分类将无法恢复", "删除分类", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
		  const path = '/hrms/position/type/delete'
	      const note = `${this.libName}刪除${data.label}分类`
	      this.$store.commit('status/SET_LOG', {path, note})
          delJobType({ id: data.id }).then((res) => {
            this.$message.success("删除成功");
            this.resetDirList().then(() => {
				this.updateDir()
			})
          });
        })
        .catch(() => {});
    },
    resetDirList() {
      return this.$store.dispatch("common/getDictMap");
    },
	updateDir() {
		this.dirList = this.$store.getters.dictMap[this.libKey]
		this.$emit('updateList', this.dirList)
	},
	checkMovPerm() {
	  const addPath = getLibName()
	  let path = '/hrms/position/type/move'
	  path = addPath ? path.replace('/hrms', `/hrms${addPath}`) : path

	  return this.userPermission.includes(path)
	},
    setDragDropEvent() {
      const container = this.$el.querySelector("tbody");
      let dragStartIndex = -1;
      let dropIndex = -1;
      container.childNodes.forEach((childNode, index) => {
		// console.log(childNode)
        if (childNode.nodeType === 1) {
          //  正常节点
        //   if (!this.disabledTypes.includes(this.dirList[index].label)) {
            childNode.setAttribute("draggable", true);
            childNode.ondragstart = (event) => {
              dragStartIndex = index;
            //   console.log("ondragstart", "dragStartIndex", dragStartIndex);
            };
            childNode.ondragover = (event) => {
              event.preventDefault();
            };
            childNode.ondragenter = (event) => {
              event.preventDefault();
              dropIndex = index;
            //   console.log("ondragenter", "dropIndex", dropIndex);
            };
            childNode.ondrop = () => {
              // console.log('ondrop')
			  if(!this.checkMovPerm()) return 
              if (dragStartIndex === dropIndex) return;
              const dragEl = this.dirList.splice(dragStartIndex, 1)[0];
              this.dirList.splice(dropIndex, 0, dragEl);
              // dragStartIndex = index;
            };
            childNode.ondragend = () => {
              //   console.log('ondragend')
              if (dragStartIndex === dropIndex) return;
              const params = {
                id: this.dirList[dragStartIndex].id,
                sn: dragStartIndex + 1,
              };

			  const path = '/hrms/position/type/move'
	      	  const note = `对${this.libName}${this.dirList[dragStartIndex].label}排序`
	          this.$store.commit('status/SET_LOG', {path, note})
              moveJobType(params).then(() => {
                this.$message.success("目录移动成功");
              });
            // };
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dir-setting {
  .header {
    display: flex;
    justify-content: space-between;
    /deep/ .el-input {
      width: 240px;
      input {
        border-radius: 15px;
      }
    }
  }
  .content {
    margin-top: 15px;
  }
}
</style>