<template>
    <div>
        <Search :params="params" :paramsHeaders="paramsHeaders" :getTableData="getTableData">
            <template slot="typeId">
                <el-select v-model="params.typeId" size="small">
                    <el-option v-for="item in typeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
            </template>
            <template slot="operate">
                <div class="extra">
                    <button @click="addJob(1)" class="btn submit-btn" v-permission="['/hrms/position/add']">添加职务</button>
                    <button @click="setSort" class="btn submit-btn" v-permission="['/hrms/position/dict/add', '/hrms/position/type/modify', '/hrms/position/type/delete', '/hrms/position/type/move']">设置分类</button>
                </div>
            </template>
        </Search>
        <CommonTable class="mt15" :headers="headers" :tableData="tableData" :maxHeight="700" :hasIndex="true">
            <template slot="typeId" slot-scope="{ row }">
                {{typeName(row)}}
            </template>
            <template slot="operate" slot-scope="{ row }" v-if="editable(row)">
                <div class="operate">
                    <el-button size="small" type="primary" plain @click="addJob(0,row)" v-permission="['/hrms/position/modify']">编辑</el-button>
                    <el-button size="small" type="danger" plain @click="delJob(row)" v-permission="['/hrms/position/delete']">删除</el-button>
                </div>
            </template>
        </CommonTable>
        
        <el-dialog :title="title" :visible.sync="openAdd" width="500px" >
            <RowForm ref="jobForm" :headers="formHeaders" :form="form" :rules="rules">
                <template slot="typeId">
                    <el-select v-model="form.typeId" size="small">
                        <el-option v-for="item in typeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                    </el-select>
                </template>
                <template slot="description">
                    <el-input type="textarea" v-model="form.description" size="small" :rows="3" resize="none"></el-input>
                </template>
            </RowForm>
            <template slot="footer">
                <button class="btn plain-btn" @click="openAdd = false">关闭</button>
                <button v-if="!form.id" class="btn submit-btn" @click="submitJob('continue')">保存并继续添加</button>
                <button class="btn submit-btn" @click="submitJob">保存</button>
            </template>
        </el-dialog>

        <el-dialog title="设置分类" width="700" :visible.sync="openSort">
            <SetSort :libName="libName" :addPath="addPath" :disabledTypes="disabledTypes" @updateList="(val) => typeList = val "></SetSort>
        </el-dialog>
    </div>
</template>

<script>
import Search from '@/components/search';
import SetSort from './SetSort.vue';
import {getSysList, addJob , editJob , delJob } from '@/api/hrAPI.js';

export default {
	name: 'duty',
    components: {
        Search,
        SetSort
    },
	props: {
        addPath: {
            type: String,
            default: '',
        },
		libName: {
			type:String,
			require: true
		}
    },
    data(){
        return {
            params: {
                typeId: '',
                name: '',
            },
            daterange: [],
            paramsHeaders: [
                {
                    label: '分类',
                    value: 'typeId',
                    slot: 'typeId'
                },
                {
                    label: '职务名称',
                    value: 'name'
                },
            ],
            headers: [
                {
                    label: '职务名称',
                    value: 'name'
                },
                {
                    label: '分类',
                    value: 'typeId',
                    slot: 'typeId'
                },
                {
                    label: '描述',
                    value: 'description'
                },
                {
                    label: '创建时间',
                    value: 'createTime'
                },
                {
                    label: '操作',
                    slot: 'operate'
                }
            ],
            tableData: [],
            formHeaders: [
                {
                    label: '职务名称',
                    value: 'name'
                },
                {
                    label: '分类',
                    value: 'typeId',
                    slot: 'typeId'
                },
                {
                    label: '描述',
                    value: 'description',
                    slot: 'description'
                },
            ],
            form: this.createFormFields(),
            openAdd: false,
            jobType: 1,
            rules: {
                name: [
                    { required: true, message: '请输入职务名称', trigger: 'change' },
                ],
                typeId: [
                    { required: true, message: '请选择职务分类', trigger: 'change' }
                ],
            },
            openSort: false,
            title: '',
			typeList: [],
			disabledTypes: []
        }
    },
    computed: {
        // typeList(){
		// 	const { addPath } = this.$route.meta
	  	// 	let key = 'position_type'
	  	// 	if(addPath) {
		// 		key = key.concat(addPath).replace('/','_')
	  	// 	} 

        //     return this.$store.getters.dictMap[key];
        // },
    },
	watch: {
		'addPath': {
			handler(val) {
	  			let key = 'position_type'
	  			if(val) {
					key = key.concat(val).replace('/','_')
					this.disabledTypes = ["职级", "人员分类", "法官等级"]
	  			} else {
					this.disabledTypes = ["职级", "人员分类", "法官等级", "职务"]
				}

				this.typeList = this.$store.getters.dictMap[key];
			},
			immediate: true
		}
	},
    created(){
        this.getTableData();
    },
    methods: {
        editable(row){
			// 法官等级
			// console.log(row)
			if(row.typeName === '法官等级' && (/[一二三四]级高级法官/.test(row.name) || row.name === '未入额'))  return  false

			const classifyList1 = ['员额法官', '综合行政']  // Lib 1
			const classifyList2 = ['员额法官', '审判辅助人员', '司法行政人员']  // Lib 2, 3
			const classifyList = this.addPath ? classifyList2 : classifyList1
			
			if(row.typeName === '人员分类' && classifyList.includes(row.name))  return  false

			const rankList1 = ['一级调研员','二级调研员', '三级调研员', '四级调研员']  
			const rankList2 = ['院领导', '中层干部', '其他']  
			const rankList = this.addPath ? rankList2 : rankList1
			if(row.typeName === '职级' && rankList.includes(row.name)) return  false

			if(!this.addPath) {
				const jobList = ['院长','副院长','政治部主任','审判委员会专职委员','其他职务']
				if(row.typeName === '职务' && jobList.includes(row.name)) return  false
			}

			return true
        },
		createFormFields() {
			return {
                id: '',
                name: '',
                typeId: '',
                description: '',
            }
		},
        typeName(data){
            const target = this.typeList.find(type => type.id === data.typeId);
            if(target){
                return target.label;
            }
        },
        getTableData(){
            getSysList(this.params).then(res => {
                this.tableData = res;
            })
        },
        addJob(type,row){
            this.jobType = type;
            if(this.jobType){
                this.title = '添加职务';
                this.form = this.createFormFields()
            }else{
                this.title = '修改职务';
                this.form = JSON.parse(JSON.stringify(row));
            }
            this.$refs.jobForm && this.$refs.jobForm.resetForm();
            this.openAdd = true;
        },
        setSort(){
            this.openSort = true;
        },
        submitJob(type){
            this.$refs.jobForm.submit().then(res => {
                if(this.jobType){
					const path = '/hrms/position/add'
		  			const note = `${this.libName}添加${this.form.name}职务`
		  			this.$store.commit('status/SET_LOG', {path, note})
                    if(type === 'continue'){
                        addJob(this.form).then(res => {
                            this.$message.success('添加成功!');
                            this.$refs.jobForm.resetForm();
                            this.getTableData();
                        })
                        // this.$refs.jobForm.resetForm();
                    }else{
                        addJob(this.form).then(res => {
                            this.$message.success('添加成功!');
                            this.openAdd = false;
                            this.getTableData();
                        })

                    }
                }else{
					const path = '/hrms/position/modify'
		  			const note = `${this.libName}编辑${this.form.name}职务`
		  			this.$store.commit('status/SET_LOG', {path, note})
                    editJob(this.form).then(res => {
                        this.$message.success('修改成功!');
                        this.openAdd = false;
                        this.getTableData();
                    })
                }

            })
        },
        delJob(row){
            this.$confirm(`确认要删除【${row.name}】吗 ？删除后，该职务将无法恢复。`, '确认删除', {
                confirmButtonText: '确定',
                type: 'warning'
            }).then(res => {
				const path = '/hrms/position/delete'
		  		const note = `${this.libName}删除${row.name}职务`
		  		this.$store.commit('status/SET_LOG', {path, note})
                delJob({ id: row.id }).then(res => {
                    this.$message.success('删除成功');
                    this.getTableData();
                })
            }).catch(err => {
                console.log(err);
            })
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.pagination{
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
}
.extra{
    margin-left: auto;
}
</style>